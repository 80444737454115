import React, { useState } from "react";
// import Cookies from 'js-cookie'; // Import the js-cookie library
import axios from "axios";
// import '../App.css';
import ChatWindow from "./ChatWindow";
import useChatBot from "./useChatBot";
// import ChatSessionManager from './ChatSessionManager';

axios.defaults.headers.common["x-requested-with"] = "anyValue";
const backendApi = process.env.REACT_APP_API_BACKEND_URL;

function ChatLog(props) {
  // State and other variables...

  const {
    chatMessages,
    userMessage,
    setUserMessage,
    agentName,
    userName,
    placeholder,
    agentTitle,
    isBotStarting,
    isAgentTyping,
    loading,
    handleSendMessage,
    setChatMessages,
    setSession,
    avatarURL,
    setAvatarUrl,
  } = useChatBot(
    1,
    `{"first_name":"Jimmy","scheduled_time":"8:00am","arrival_time":"8:30am","next_shift":"January 29, 8:00am","streak":"17"}`,
    props.useCase,
    props.surveyLink,
    true
  );
  const headerText = props.header;
  //   setSessionId('4F8c8HYLsUkg')
  const [inputSessionId, setInputSessionId] = useState("");

  const fetchConversationLogs = async () => {
    if (!inputSessionId) {
      return;
    }
    try {
      const response = await axios.get(
        `${backendApi}/conversation-sessions/${inputSessionId}/logs`
      );
      const logs = response.data; // Assuming the API returns logs directly, adjust based on actual API response structure
      const transformedLogs = logs.map((log) => ({
        role: log.source === "agent" ? "assistant" : "person", // Adjust based on your sources
        content: log.message_content,
      }));
      console.log(transformedLogs);
      setChatMessages(transformedLogs); // Update chat messages with logs
    } catch (error) {
      console.error("Error fetching conversation logs:", error);
      // Handle error appropriately
    }
  };

  const handleSessionIdChange = (event) => {
    setInputSessionId(event.target.value); // Update state based on input
  };

  // const handleSubmitSessionId = () => {
  //   setInputSessionId(inputSessionId);
  // };
  return (
    <div
      className="app-container"
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%", // Take full width to center content
          maxWidth: "600px", // Set a max width for the form
          margin: "0 auto", // Center the div
          padding: "20px", // Add some padding around the form
        }}
      >
        <input
          type="text"
          value={inputSessionId}
          onChange={handleSessionIdChange}
          placeholder="Enter session ID"
          style={{
            width: "100%", // Make the input take full width of the parent div
            marginBottom: "10px", // Add some space below the input
            padding: "10px", // Make the input a bit taller and more comfortable to type in
          }}
        />
        <button
          onClick={fetchConversationLogs}
          style={{
            padding: "10px 20px", // Increase padding for better visibility and clickability
            cursor: "pointer", // Change cursor to pointer to indicate it's clickable
          }}
        >
          Load Session
        </button>
      </div>
      <ChatWindow
        chatMessages={chatMessages}
        handleSendMessage={handleSendMessage}
        loading={loading}
        userMessage={userMessage}
        setUserMessage={setUserMessage}
        testing={true}
        headerText={headerText}
        setSession={setSession}
        isInputEnabled={false}
        agent_name={agentName}
        user_name={userName}
        placeholder={placeholder}
        isBotStarting={isBotStarting}
        isAgentTyping={isAgentTyping}
        avatarURL={avatarURL}
        setAvatarUrl={setAvatarUrl}
        rapid={props.rapid}
        agentTitle={agentTitle}
      />
      {/* )} */}
    </div>
  );
}

export default ChatLog;
