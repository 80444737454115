import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/App";

// Conditional Smartlook script loading based on REACT_APP_DEV
if (process.env.REACT_APP_DEV !== "true") {
  const scriptContent = `
    window.smartlook || (function (d) {
      var o = smartlook = function () { o.api.push(arguments) }, h = d.getElementsByTagName('head')[0];
      var c = d.createElement('script'); o.api = new Array(); c.async = true; c.type = 'text/javascript';
      c.charset = 'utf-8'; c.src = 'https://web-sdk.smartlook.com/recorder.js'; h.appendChild(c);
    })(document);
    smartlook('init', 'de57747e51c761c4827849adf4c127dc9987ba15', { region: 'eu' });
  `;

  const scriptElem = document.createElement("script");
  scriptElem.type = "text/javascript";
  scriptElem.innerHTML = scriptContent;
  document.head.appendChild(scriptElem);
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
