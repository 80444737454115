// ChatComponent.js
import React, { useState, useEffect, useRef } from "react";
import Modal from "./Modal"; // Ensure this Modal component is also generic and reusable

// const WAIT_MINUTES = parseInt(process.env.REACT_APP_INACTIVITY_TIMEOUT) || 5;
const WAIT_MINUTES = Number(process.env.REACT_APP_INACTIVITY_TIMEOUT);
// const ADDITIONAL_WAIT_MINUTES = parseInt(process.env.REACT_APP_ADDITIONAL_INACTIVITY_TIMEOUT) || 1;

const ADDITIONAL_WAIT_MINUTES = Number(
  process.env.REACT_APP_ADDITIONAL_INACTIVITY_TIMEOUT
);

const ChatSessionManager = ({
  onSessionEnd,
  sessionInProgress,
  sessionId,
  userName,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const modalVisibleRef = useRef(isModalVisible);

  const [modalContent, setModalContent] = useState("");
  const timerRef = useRef(null);
  const [sessionEnded, setSessionEnded] = useState(false);
  const currentSessionRef = useRef(sessionId);
  useEffect(() => {
    currentSessionRef.current = sessionId;
  }, [sessionId]);
  // You can add any logic here that you want to perform when `sessionId` changes.

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Check if there's an ongoing session and it hasn't ended
      if (sessionInProgress && !sessionEnded) {
        // Set the returnValue property of the event to a string.
        // Modern browsers display their own message, not this string.
        event.returnValue = ""; // Setting to a string triggers the confirmation dialog
      }
    };

    // Add the event listener when the component mounts
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener when the component unmounts or the session ends
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [sessionInProgress, sessionEnded]); // Depend on session state to attach or detach the event listener
  const removeActivityListeners = () => {
    // window.removeEventListener('mousemove', handleActivity);
    window.removeEventListener("keydown", handleActivity);
  };
  const showModal = (content) => {
    setModalContent(content);
    setIsModalVisible(true);
    modalVisibleRef.current = true; // Update ref here
  };

  const hideModal = () => {
    setIsModalVisible(false);
    modalVisibleRef.current = false; // Update ref here
  };

  const handleActivity = () => {
    // If there is activity, reset the timer
    if (modalVisibleRef.current) return;
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(
      showUnresponsiveMessage,
      WAIT_MINUTES * 60000
    );
  };

  const showUnresponsiveMessage = () => {
    if (!sessionEnded) {
      showModal("unresponsive");
      // Clear any previous timeout to ensure endSessionDueToNoResponse is not called twice
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(
        endSessionDueToNoResponse,
        ADDITIONAL_WAIT_MINUTES * 60000
      );
    }
  };

  const endSessionDueToNoResponse = () => {
    if (!sessionEnded) {
      onSessionEnd(currentSessionRef.current);
      setSessionEnded(true); // Ensure we track that the session has ended
      hideModal();
      clearTimeout(timerRef.current); // Clear the timeout to prevent it from being called again
      removeActivityListeners(); // Stop tracking any activity
    }
  };

  const handleUserResponse = (response) => {
    modalVisibleRef.current = false;

    clearTimeout(timerRef.current);

    if (response === "yes" && !sessionEnded) {
      hideModal();
      // Reset the timer if the user is still there
      timerRef.current = setTimeout(
        showUnresponsiveMessage,
        WAIT_MINUTES * 60000
      );
    } else if (response === "end") {
      // User confirmed to end the session
      setSessionEnded(true);
      onSessionEnd(currentSessionRef.current);
      removeActivityListeners(); // Clean up if session ends
    } else {
      // In case of any other response, just hide the modal
      hideModal();
    }
  };

  useEffect(() => {
    // Initialize the inactivity timer
    if (sessionInProgress) {
      timerRef.current = setTimeout(
        showUnresponsiveMessage,
        WAIT_MINUTES * 60000
      );

      // Event listeners for user activity
      // window.addEventListener('mousemove', handleActivity);
      window.addEventListener("keydown", handleActivity);

      return () => {
        // Cleanup on unmount
        clearTimeout(timerRef.current);
        // window.removeEventListener('mousemove', handleActivity);
        window.removeEventListener("keydown", handleActivity);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionInProgress]);

  return (
    <div>
      <Modal
        userName={userName}
        isVisible={isModalVisible}
        content={modalContent}
        onUserResponse={handleUserResponse}
      />
    </div>
  );
};

export default ChatSessionManager;
