import React, { useState, useEffect } from "react";
const backendApi = process.env.REACT_APP_API_BACKEND_URL;

export function PromptVersionManager() {
  const [promptVersions, setPromptVersions] = useState([]);
  const [currentVersion, setCurrentVersion] = useState(null);
  const [useCases, setUseCases] = useState([]);
  const [selectedUseCase, setSelectedUseCase] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const fetchUseCases = async () => {
    const response = await fetch(`${backendApi}/use_cases`);
    const data = await response.json();
    if (data?.use_cases) {
      setUseCases(data.use_cases);
    } else {
      setUseCases([]);
    }
  };

  const fetchPromptVersions = async () => {
    console.log("selectedUseCase", selectedUseCase);
    const endpoint = selectedUseCase
      ? `${backendApi}/prompt_versions?useCase=${selectedUseCase}`
      : `${backendApi}/prompt_versions`;
    const response = await fetch(endpoint);
    const data = await response.json();
    setPromptVersions(data);
  };

  useEffect(() => {
    fetchUseCases();
    fetchPromptVersions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchPromptVersions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUseCase]);

  const handleEdit = (version) => {
    setCurrentVersion(version);
    setModalOpen(true);
  };

  const handleDelete = async (id) => {
    await fetch(`${backendApi}/prompt_versions/delete/${id}`, {
      method: "DELETE",
    });
    fetchPromptVersions();
  };

  const handleActivate = async (id) => {
    await fetch(`${backendApi}/prompt_versions/activate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ version_id: id }),
    });
    fetchPromptVersions();
  };

  return (
    <div>
      <button
        onClick={() => {
          setModalOpen(true);
          setCurrentVersion(null);
        }}
      >
        Add New Version
      </button>
      <select
        value={selectedUseCase}
        onChange={(e) => setSelectedUseCase(e.target.value)}
      >
        <option value="">Select a Use Case</option>
        {useCases.map((useCase) => (
          <option key={useCase.id} value={useCase.id}>
            {useCase.use_case_name}
          </option>
        ))}
      </select>
      <table>
        <thead>
          <tr>
            <th>Prompt</th>
            <th>Active</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {promptVersions.map((version) => (
            <tr key={version.id}>
              <td>{version.prompt}</td>
              <td>{version.is_active ? "Yes" : "No"}</td>
              <td>
                <button onClick={() => handleEdit(version)}>Edit</button>
                <button onClick={() => handleActivate(version.id)}>
                  Activate
                </button>
                <button onClick={() => handleDelete(version.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {modalOpen && (
        <PromptVersionForm
          version={currentVersion}
          onClose={() => setModalOpen(false)}
          fetchPromptVersions={fetchPromptVersions}
        />
      )}
    </div>
  );
}

export function PromptVersionForm({ version, onClose, fetchPromptVersions }) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState(""); // This acts as 'prompt'
  const [useCaseStep, setUseCaseStep] = useState(""); // New field for use case step
  const [isActive, setIsActive] = useState(false);

  // Effect to populate form when editing an existing version
  useEffect(() => {
    if (version) {
      setTitle(version.title);
      setDescription(version.description);
      setContent(version.content);
      setUseCaseStep(version.use_case_step || "");
      setIsActive(version.is_active);
    }
  }, [version]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const method = version ? "PUT" : "POST";
    const apiUrl = version
      ? `${backendApi}/prompt_versions/update/${version.id}`
      : `${backendApi}/prompt_versions/create`;

    // Building the formData object with necessary fields
    const formData = {
      usecase_id: version ? version.usecase_id : null, // Assuming 'usecase_id' is passed in version prop when editing
      use_case_step: useCaseStep, // Use case step value
      title, // Assuming title is required but may not be directly used as a model field
      description, // Additional field for context
      prompt: content, // 'content' field is sent as 'prompt'
      is_active: isActive,
    };

    try {
      const response = await fetch(apiUrl, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        fetchPromptVersions();
        onClose(); // Close the form modal on successful submission
      } else {
        const errorResponse = await response.json();
        alert("Failed to submit: " + JSON.stringify(errorResponse));
      }
    } catch (error) {
      console.error("Failed to submit form:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div className="prompt-version-form">
      <h2>{version ? "Edit Prompt Version" : "Create Prompt Version"}</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Title:
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </label>
        <label>
          Description:
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </label>
        <label>
          Content (Prompt):
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          />
        </label>
        <label>
          Use Case Step:
          <input
            type="number"
            value={useCaseStep}
            onChange={(e) => setUseCaseStep(e.target.value)}
            required
          />
        </label>
        <label>
          Active:
          <input
            type="checkbox"
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
          />
        </label>
        <button type="submit">Submit</button>
        <button type="button" onClick={onClose}>
          Cancel
        </button>
      </form>
    </div>
  );
}
