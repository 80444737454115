// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes pulsate {
  0% {
    filter: drop-shadow(0 0 2px #60cb5c) drop-shadow(0 0 6px #60cb5c)
      drop-shadow(0 0 12px #60cb5c);
  }
  50% {
    filter: drop-shadow(0 0 15px #08bb01) drop-shadow(0 0 30px #08bb01)
      drop-shadow(0 0 45px #08bb01);
  }
  100% {
    filter: drop-shadow(0 0 2px #60cb5c) drop-shadow(0 0 6px #60cb5c)
      drop-shadow(0 0 12px #60cb5c);
  }
}

.glowing {
  animation: pulsate 2s infinite;
  color: #007bff;
}

.normal-icon {
  color: #007bff;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE;IACE;mCAC+B;EACjC;EACA;IACE;mCAC+B;EACjC;EACA;IACE;mCAC+B;EACjC;AACF;;AAEA;EACE,8BAA8B;EAC9B,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n@keyframes pulsate {\n  0% {\n    filter: drop-shadow(0 0 2px #60cb5c) drop-shadow(0 0 6px #60cb5c)\n      drop-shadow(0 0 12px #60cb5c);\n  }\n  50% {\n    filter: drop-shadow(0 0 15px #08bb01) drop-shadow(0 0 30px #08bb01)\n      drop-shadow(0 0 45px #08bb01);\n  }\n  100% {\n    filter: drop-shadow(0 0 2px #60cb5c) drop-shadow(0 0 6px #60cb5c)\n      drop-shadow(0 0 12px #60cb5c);\n  }\n}\n\n.glowing {\n  animation: pulsate 2s infinite;\n  color: #007bff;\n}\n\n.normal-icon {\n  color: #007bff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
