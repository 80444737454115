import React, { useEffect, useState } from "react";
import MeetingChatBot from "./MeetingChatBot"; // Adjust the import path as necessary
import Cookies from "js-cookie";
import axios from "axios";
const backendApi = process.env.REACT_APP_API_BACKEND_URL;

function getUserIDFromCookie() {
  const userID = Cookies.get("inspira-chat");
  console.log("User ID:", userID);
  return userID || null;
}

function getRemoteTrackingIDFromCookie() {
  console.log("All cookies:", document.cookie);

  const allCookies = document.cookie.split("; ");
  console.log("All cookies array:", allCookies);

  const posthogCookie = allCookies.find((cookie) =>
    cookie.includes("_posthog")
  );
  console.log("PostHog cookie found:", posthogCookie);

  if (posthogCookie) {
    const cookieValue = posthogCookie.split("=")[1];
    console.log("Raw cookie value:", cookieValue);

    try {
      const decodedValue = decodeURIComponent(cookieValue);
      const parsedValue = JSON.parse(decodedValue);
      console.log("Parsed cookie value:", parsedValue);

      if (parsedValue && parsedValue.distinct_id) {
        console.log("Distinct ID found:", parsedValue.distinct_id);
        return parsedValue.distinct_id;
      } else {
        console.log("No distinct_id found in parsed cookie value");
      }
    } catch (error) {
      console.error("Failed to parse PostHog cookie value:", error);
    }
  } else {
    console.log("No PostHog cookie found");
  }

  console.log("Returning null as no valid distinct_id was found");
  return null;
}

function getGoogleAnalyticsIDFromCookie() {
  let googleAnalyticsID = Cookies.get("_ga");
  Cookies.set("optin", "false");
  Cookies.set("enhanced_optin", "false");
  if (googleAnalyticsID) {
    googleAnalyticsID = googleAnalyticsID.substring(7);
  } else {
    console.log("Google Analytics ID not found.");
    googleAnalyticsID = null;
  }
  return googleAnalyticsID;
}

function SalesChatBotWrapper(props) {
  const { params } = props;
  const [agentName, setAgentName] = useState("Greg Smith");
  const [avatarURL, setAvatarUrl] = useState(
    "https://inspira-public-images.s3.amazonaws.com/agent-photo-cropped.png"
  );
  const [agentTitle, setAgentTitle] = useState("Pre-Sales Support");
  const [isBotStarting, setIsBotStarting] = useState(false);

  console.log("isBotStarting", isBotStarting);
  const external_user_id = getUserIDFromCookie();
  const google_analytics_id = getGoogleAnalyticsIDFromCookie();
  const remote_tracking_id = getRemoteTrackingIDFromCookie();

  // const remote_tracking_id = "1234567890";
  const { appointmentId } = { appointmentId: "1" };

  const useCaseConfig = {
    token: params?.token,
    company_id: params?.company_id,
    use_case_id: params?.use_case_id,
    chatbot_type_id: params?.chatbot_type_id,
    remote_tracking_id: remote_tracking_id,
    platform_id: 1,
    user_id: external_user_id,
    is_external_user: true,
  };

  const getChatbotDetails = async () => {
    try {
      setIsBotStarting(true);
      const response = await axios.get(
        `${backendApi}/companies/${params?.company_id}/chatbot/${params?.chatbot_type_id}/`
      );
      console.log("response.data", response.data);
      if (response.data) {
        setAgentName(
          response.data.agent_name && response.data.agent_name.trim() !== ""
            ? response.data.agent_name
            : "Greg Smith"
        );
        setAvatarUrl(
          response.data.agent_image_url &&
            response.data.agent_image_url.trim() !== ""
            ? response.data.agent_image_url
            : "https://inspira-public-images.s3.amazonaws.com/agent-photo-cropped.png"
        );
        setAgentTitle(
          response.data.agent_title && response.data.agent_title.trim() !== ""
            ? response.data.agent_title
            : "Pre-Sales Support"
        );
      }
    } catch (error) {
      console.error("Error fetching chatbot details:", error);
    } finally {
      setIsBotStarting(false);
    }
  };

  useEffect(() => {
    getChatbotDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.company_id, params?.chatbot_type_id]);

  return (
    <MeetingChatBot
      agentName={agentName}
      avatarURL={avatarURL}
      agentTitle={agentTitle}
      systemMessage={{
        appointmentId: appointmentId,
        is_external_user: true,
        google_analytics_id: google_analytics_id,
      }}
      header="Ask us anything!"
      useCase={params?.use_case_id}
      cookieEnabled={true}
      salesBot={true}
      useCaseConfig={useCaseConfig}
      endpoint="company_uc_handler"
      showTTSandSTTIcons={false}
    />
  );
}

export default SalesChatBotWrapper;
