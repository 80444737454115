import { FiUser } from "react-icons/fi";
import React, { useEffect, useState, useRef } from "react";
import Cookies from "js-cookie";
import Markdown from "./Markdown";
import {
  faMicrophone,
  faVolumeHigh,
  faVolumeMute,
  faMicrophoneSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useVoiceToText from "./useVoiceToText";

function isValidURL(string) {
  try {
    new URL(string);
    return true;
  } catch (error) {
    return false;
  }
}

function ChatWindow({
  chatMessages,
  handleSendMessage,
  loading,
  userMessage,
  setUserMessage,
  headerText,
  setSession,
  isInputEnabled,
  agent_name,
  agentTitle,
  user_name,
  setUserName,
  testing,
  placeholder,
  isBotStarting,
  isAgentTyping,
  avatarURL,
  completedChatCookie,
  cookieEnabled,
  appointmentId,
  showCredit,
  isSoundOn,
  requestAudioAuthorization,
  showTTSandSTTIcons,
}) {
  const messagesEndRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(avatarURL);
  const agent_icon_local =
    "https://inspira-public-images.s3.amazonaws.com/agent-photo-cropped.png";
  const [isInputLocked, setInputLocked] = useState(false);
  const [showNameError, setShowNameError] = useState(false);
  const [timer, setTimer] = useState(null);
  const timeoutRef = useRef();
  const [showPreloader, setShowPreloader] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isAudioPlaybackEnabled, setIsAudioPlaybackEnabled] = useState(false);
  const { startListening, stopListening, clearTranscript } = useVoiceToText({
    lang: "en-US",
    continuous: true,
    setUserMessage: setUserMessage,
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const isMobileWidth = () => {
    return window.innerWidth <= 768;
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileWidth());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const initialImageSrc = isValidURL(avatarURL)
      ? avatarURL
      : agent_icon_local;
    setImageSrc(initialImageSrc);
    if (chatMessages.length > 0 || isAgentTyping) {
      setShowPreloader(false);
      messagesEndRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [chatMessages, avatarURL, isAgentTyping]);

  useEffect(() => {
    if (chatMessages.length > 0 || isAgentTyping) {
      setShowPreloader(false);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
  }, [chatMessages, isAgentTyping]);

  useEffect(() => {
    if (timer) {
      timeoutRef.current = setTimeout(() => {
        const now = new Date();
        if (now - timer >= 20000) {
          setShowPreloader(true);
        }
      }, 10000);

      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }
  }, [timer]);

  const handleImageError = () => {
    setImageSrc(agent_icon_local);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && userMessage.trim() !== "") {
      if (isInputEnabled) {
        modifiedHandleSendMessage();
      }
      e.preventDefault();
    }
  };

  const handleSetSession = () => {
    if (!user_name.trim()) {
      setShowNameError(true);
    } else {
      setShowNameError(false);
      setInputLocked(!isInputLocked);
      setSession();
      if (cookieEnabled) {
        Cookies.set(completedChatCookie, "true", { expires: 30 });
      }
    }
  };

  const modifiedHandleSendMessage = () => {
    if (isListening) {
      stopListening();
      setIsListening(false);
    }
    if (userMessage.trim() === "") {
      return;
    }

    setTimer(new Date());
    if (appointmentId) {
      handleSendMessage(false, appointmentId);
    } else {
      handleSendMessage(false);
    }

    clearTranscript();
    setShowPreloader(false);
  };

  const handleTyping = (e) => {
    setUserMessage(e.target.value);
  };

  const handleVoiceToText = () => {
    if (isListening) {
      stopListening();
      setIsListening(false);
      clearTranscript();
      modifiedHandleSendMessage();
    } else {
      startListening();
      setIsListening(true);
    }
  };

  const handleTextToSpeech = () => {
    if (isAudioPlaybackEnabled) {
      requestAudioAuthorization();
      setIsAudioPlaybackEnabled(false);
    } else {
      requestAudioAuthorization();
      setIsAudioPlaybackEnabled(true);
    }
  };

  return (
    <div className="chat-window">
      {!testing ? (
        <div className="input-button-container">
          <div
            className={`first-name-section ${showNameError ? "show-tooltip" : ""}`}
          >
            <div className="error-tooltip">Please enter a name.</div>
            <input
              type="text"
              value={user_name}
              onChange={(e) => setUserName(e.target.value)}
              placeholder="Enter first name"
              className={`styled-input ${isInputLocked ? "disabled-input" : "highlight"}`}
              readOnly={isInputLocked}
            />
          </div>
          <div>
            <button
              className={`save-btn small-btn  ${isInputLocked ? "highlight" : ""}`}
              onClick={handleSetSession}
              disabled={isInputLocked}
            >
              Start Conversation
            </button>
          </div>
        </div>
      ) : null}

      <div className="chat-messages">
        {isBotStarting && (
          <div className="agent">
            One moment please, an agent will be right with you.
          </div>
        )}

        {chatMessages.map((msg, idx) => (
          <div
            key={idx}
            className={msg.role}
            ref={idx === chatMessages.length - 1 ? messagesEndRef : null}
          >
            {msg.role === "assistant" && (
              <div className="icon-with-name">
                {imageSrc ? (
                  <img
                    src={imageSrc}
                    alt={"Agent"}
                    onError={handleImageError}
                    className="chat-icon circular-icon"
                  />
                ) : null}
                <span
                  className="agent-name"
                  style={{
                    fontWeight: "bold",
                    fontSize: isMobile ? "0.75em" : "1em",
                  }}
                >
                  {" "}
                  {agent_name}
                  {agentTitle ? `, ${agentTitle}` : ""}
                </span>
              </div>
            )}

            {msg.role === "person" && (
              <div className="icon-with-name">
                <FiUser className="user-icon circular-icon" />
                <span className="user-name" style={{ fontWeight: "bold" }}>
                  {user_name}
                </span>
              </div>
            )}
            <Markdown msg={msg} />
            {msg.link && (
              <div>
                <a href={msg.link} target="_blank" rel="noopener noreferrer">
                  Appointment Link
                </a>
              </div>
            )}
          </div>
        ))}
        {isAgentTyping && (
          <div className="assistant">
            <div className="icon-with-name">
              {imageSrc ? (
                <img
                  src={imageSrc}
                  alt={"Agent"}
                  className="chat-icon circular-icon"
                />
              ) : null}
              <span
                className="agent-name"
                style={{
                  fontWeight: "bold",
                  fontSize: isMobile ? "0.75em" : "1em",
                }}
              >
                {" "}
                {agent_name}
                {agentTitle ? `, ${agentTitle}` : ""}
              </span>
            </div>
            <span style={{ fontSize: isMobile ? "0.75em" : "1em" }}>
              Typing{" "}
            </span>
            <span className="typing-dots">
              <span className="typing-dot"></span>
              <span className="typing-dot"></span>
              <span className="typing-dot"></span>
            </span>
          </div>
        )}
        {loading && (
          <img
            src="/TrueTime_PreLoader_Transparent.gif"
            alt="Loading..."
            className="loading-gif"
          />
        )}
        {showPreloader && (
          <img
            src="/TrueTime_PreLoader_Transparent.gif"
            alt="Loading..."
            className="loading-gif"
          />
        )}
      </div>
      <div className="chat-input">
        {isMobile ? (
          <textarea
            className="chat-input-textarea"
            value={userMessage}
            onChange={handleTyping}
            placeholder={placeholder}
            onKeyPress={handleKeyPress}
            disabled={!isInputEnabled}
            rows={5}
          />
        ) : (
          <input
            value={userMessage}
            onChange={handleTyping}
            placeholder={placeholder}
            onKeyPress={handleKeyPress}
            disabled={!isInputEnabled}
          />
        )}
        <button
          onClick={modifiedHandleSendMessage}
          className={isInputEnabled ? "button-select" : "button-disabled"}
          disabled={!isInputEnabled || userMessage.length > 200}
        >
          Send
        </button>
        {showTTSandSTTIcons && (
          <div>
            {isAudioPlaybackEnabled && (
              <FontAwesomeIcon
                icon={faVolumeHigh}
                size="2x"
                onClick={handleTextToSpeech}
                style={{ color: "#007BFF" }}
              />
            )}
            {!isAudioPlaybackEnabled && (
              <FontAwesomeIcon
                icon={faVolumeMute}
                size="2x"
                onClick={handleTextToSpeech}
                style={{ color: "#007BFF" }}
              />
            )}
            {isInputEnabled && (
              <FontAwesomeIcon
                icon={faMicrophone}
                size="2x"
                className={"normal-icon"}
                onClick={handleVoiceToText}
                style={{ color: isListening ? "#08bb01" : "#007BFF" }}
              />
            )}
            {!isInputEnabled && (
              <FontAwesomeIcon
                icon={faMicrophoneSlash}
                size="2x"
                textDecoration={"Input is disabled right now"}
                style={{ color: "#007BFF" }}
              />
            )}
          </div>
        )}
        {userMessage.length > 400 && (
          <div className="warning">Message cannot exceed 400 characters.</div>
        )}
      </div>
      {showCredit && (
        <p className="chat-powered-by-text">
          Powered by{" "}
          <a
            href="https://inspira.ai/ai-sales-agents"
            target="_blank"
            rel="noreferrer noopener"
          >
            Inspira AI
          </a>
        </p>
      )}
    </div>
  );
}

export default ChatWindow;
