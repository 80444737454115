import ReactMarkdown from "react-markdown";

const Markdown = ({ msg }) => {
  return (
    <ReactMarkdown
      children={msg.content}
      components={{
        // Custom rendering for links
        a: ({ node, children, ...props }) => (
          <a
            target="_blank"
            rel="noopener noreferrer"
            // Provide an aria-label for accessibility, defaulting to 'External Link' if no children are present
            aria-label={
              children.length > 0 ? children.toString() : "External Link"
            }
            {...props}
          >
            {children}
          </a>
        ),
      }}
    />
  );
};

export default Markdown;
