import React from "react";

const Modal = ({ isVisible, content, onUserResponse, userName }) => {
  if (!isVisible) return null;

  // You could have more sophisticated logic here to display different content based on the "content" prop.
  let modalBody;
  switch (content) {
    case "confirmClose":
      modalBody = (
        <>
          <p>You are closing the conversation, right?</p>
          <button onClick={() => onUserResponse("yes")}>Yes</button>
          <button onClick={() => onUserResponse("no")}>No</button>
        </>
      );
      break;
    case "unresponsive":
      modalBody = (
        <>
          <p>{userName} are you still with me?</p>
          <button onClick={() => onUserResponse("yes")}>Yes</button>
        </>
      );
      break;
    case "goodbye":
      modalBody = (
        <p>
          Seems like you have gotten busy with something else. Talk to you
          later!
        </p>
      );
      break;
    default:
      modalBody = <p>Unknown content</p>;
  }

  return (
    <div className="modal">
      <div className="modal-content">{modalBody}</div>
    </div>
  );
};

export default Modal;
