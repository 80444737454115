import React, { useEffect, useState } from "react";
import { FiSettings, FiMic, FiVolume2 } from "react-icons/fi";
import ChatWindow from "./ChatWindow";
import Cookies from "js-cookie";
import useChatBot from "./useChatBot";
import ChatSessionManager from "./ChatSessionManager";

function PersonalAssistant(props) {
  const headerText = props.header;
  const HEARTBEAT = process.env.REACT_APP_HEARTBEAT;
  const { appointmentId } = props;

  const [isMicrophoneOn, setIsMicrophoneOn] = useState(false);

  const [recognition, setRecognition] = useState(null);

  const {
    chatMessages,
    userMessage,
    setUserMessage,
    showCredit,
    showConfig,
    setShowConfig,
    isInputEnabled,
    userName,
    setUserName,
    placeholder,
    sessionId,
    isBotStarting,
    isAgentTyping,
    loading,
    handleSendMessage,
    setSession,
    setAvatarUrl,
    completedChatCookie,
    onSessionEnd,
    sessionInProgress,
    sendHeartbeat,
    setToken,
    setUserId,
    setPlatformId,
    setCompanyId,
    setUseCaseId,
    setChatMessages,
    setSessionIDRequired,
    setIsSoundOn,
    isSoundOn,
    setEndpoint,
    setConfig,
    agentName: defaultAgentName,
    agentTitle: defaultAgentTitle,
    avatarURL: defaultAvatarUrl,
    requestAudioAuthorization,
  } = useChatBot(
    props.useCaseConfig?.company_id,
    props.systemMessage,
    props.useCase,
    props.surveyLink,
    true
  );

  let agentName = props.agentName ? props.agentName : defaultAgentName;
  let agentTitle = props.agentTitle ? props.agentTitle : defaultAgentTitle;
  let avatarURL = props.avatarURL ? props.avatarURL : defaultAvatarUrl;

  useEffect(() => {
    if (props.useCaseConfig) {
      if (props.useCaseConfig.token) {
        setToken(props.useCaseConfig.token);
      }
      if (props.useCaseConfig.user_id) {
        setUserId(props.useCaseConfig.user_id);
      }
      if (props.useCaseConfig.platform_id) {
        setPlatformId(props.useCaseConfig.platform_id);
      }
      if (props.useCaseConfig.company_id) {
        setCompanyId(props.useCaseConfig.company_id);
      }
      if (props.useCaseConfig.use_case_id) {
        setUseCaseId(props.useCaseConfig.use_case_id);
      }
      setConfig(props.useCaseConfig);
    }
  }, [
    props.useCaseConfig,
    setToken,
    setUserId,
    setPlatformId,
    setCompanyId,
    setUseCaseId,
    setConfig,
  ]);

  useEffect(() => {
    console.log("setEndpoint", "multi-agent");
    setEndpoint("multi-agent");
  }, [setEndpoint]);

  useEffect(() => {
    const hasCompletedChat = Cookies.get(completedChatCookie) === "true";
    if (hasCompletedChat) {
      window.location.href = "https://www.google.com";
    }
  }, [completedChatCookie]);

  useEffect(() => {
    if (sessionInProgress) {
      const heartbeatInterval = setInterval(
        () => sendHeartbeat(sessionId),
        HEARTBEAT * 60 * 1000
      );
      return () => {
        clearInterval(heartbeatInterval);
      };
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    setSessionIDRequired(false);
    console.log("setSessionIDRequired", false);
    setChatMessages([
      { role: "assistant", content: "Hi there! How can I help you today?" },
    ]);
  }, []); // eslint-disable-line

  useEffect(() => {
    if ("webkitSpeechRecognition" in window) {
      const speechRecognition = new window.webkitSpeechRecognition();
      speechRecognition.continuous = true;
      speechRecognition.interimResults = true;
      speechRecognition.lang = "en-US";

      speechRecognition.onresult = (event) => {
        let interimTranscript = "";
        let finalTranscript = "";

        for (let i = 0; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalTranscript += transcript;
          } else {
            interimTranscript += transcript;
          }
        }
        setUserMessage(interimTranscript || finalTranscript);
        if (finalTranscript) {
          handleSendMessage(true);
        }
      };

      speechRecognition.onerror = (event) => {
        console.error("Speech recognition error:", event.error);
        setIsMicrophoneOn(false);
      };

      speechRecognition.onend = () => {
        setIsMicrophoneOn(false);
      };

      setRecognition(speechRecognition);
    } else {
      console.error("Speech recognition not supported in this browser.");
    }
  }, []); // eslint-disable-line

  const toggleMicrophone = () => {
    if (isMicrophoneOn) {
      recognition.stop();
    } else {
      recognition.start();
    }
    setIsMicrophoneOn(!isMicrophoneOn);
  };

  const toggleSound = () => {
    setIsSoundOn(!isSoundOn);
    // Add functionality for enabling/disabling sound output
  };

  return (
    <div className="app-container">
      <button
        className="gearbox"
        onClick={() => setShowConfig(!showConfig)}
        style={{ display: "none" }}
      >
        <FiSettings />
      </button>
      <ChatSessionManager
        onSessionEnd={onSessionEnd}
        sessionInProgress={sessionInProgress}
        sessionId={sessionId}
        userName={userName}
      />
      <ChatWindow
        chatMessages={chatMessages}
        handleSendMessage={handleSendMessage}
        loading={loading}
        userMessage={userMessage}
        setUserMessage={setUserMessage}
        testing={true}
        headerText={headerText}
        setSession={setSession}
        isInputEnabled={isInputEnabled}
        agent_name={agentName}
        agentTitle={agentTitle}
        user_name={userName}
        setUserName={setUserName}
        placeholder={placeholder}
        isBotStarting={isBotStarting}
        isAgentTyping={isAgentTyping}
        avatarURL={avatarURL}
        setAvatarUrl={setAvatarUrl}
        completedChatCookie={completedChatCookie}
        cookieEnabled={props.cookieEnabled}
        appointmentId={appointmentId}
        showCredit={showCredit}
        requestAudioAuthorization={requestAudioAuthorization}
        showTTSandSTTIcons={true}
      />
      <div className="microphone-sound-buttons">
        <button
          onClick={toggleMicrophone}
          className={isMicrophoneOn ? "button-active" : ""}
        >
          <FiMic />
        </button>
        <button
          onClick={toggleSound}
          className={isSoundOn ? "button-active" : ""}
        >
          <FiVolume2 />
        </button>
      </div>
    </div>
  );
}

export default PersonalAssistant;
