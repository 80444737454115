import React, { useEffect } from "react";
// import Cookies from 'js-cookie'; // Import the js-cookie library
import "../App.css";
import { FiSettings } from "react-icons/fi";
import ChatWindow from "./ChatWindow";
import Cookies from "js-cookie"; // Import the js-cookie library
import useChatBot from "./useChatBot";
import ChatSessionManager from "./ChatSessionManager";

function MeetingChatBot(props) {
  // State and other variables...

  const headerText = props.header;
  const HEARTBEAT = process.env.REACT_APP_HEARTBEAT;
  const { appointmentId, salesBot, showTTSandSTTIcons } = props;

  const {
    chatMessages,
    userMessage,
    setUserMessage,
    showCredit,
    showConfig,
    setShowConfig,
    isInputEnabled,
    userName,
    setUserName,
    placeholder,
    sessionId,
    isBotStarting,
    isAgentTyping,
    loading,
    handleSendMessage,
    setSession,
    setAvatarUrl,
    completedChatCookie,
    onSessionEnd,
    sessionInProgress,
    sendHeartbeat,
    setToken,
    setUserId,
    setPlatformId,
    setCompanyId,
    setUseCaseId,
    setChatMessages,
    setSessionIDRequired,
    setSessionId,
    setEndpoint,
    setConfig,
    agentName: defaultAgentName,
    agentTitle: defaultAgentTitle,
    avatarURL: defaultAvatarUrl,
    requestAudioAuthorization,
  } = useChatBot(
    props.useCaseConfig?.company_id,
    props.systemMessage,
    props.useCase,
    props.surveyLink,
    true
  );

  // Use `props.agentTitle` and `props.avatarURL` if provided, otherwise use values from `useChatBot`
  let agentName = props.agentName ? props.agentName : defaultAgentName;
  let agentTitle = props.agentTitle ? props.agentTitle : defaultAgentTitle;
  let avatarURL = props.avatarURL ? props.avatarURL : defaultAvatarUrl;

  console.log("avatarURL", avatarURL);

  useEffect(() => {
    if (props.useCaseConfig) {
      if (props.useCaseConfig.token) {
        setToken(props.useCaseConfig.token);
      }
      if (props.useCaseConfig.user_id) {
        setUserId(props.useCaseConfig.user_id);
      }
      if (props.useCaseConfig.platform_id) {
        setPlatformId(props.useCaseConfig.platform_id);
      }
      if (props.useCaseConfig.company_id) {
        setCompanyId(props.useCaseConfig.company_id);
      }
      if (props.useCaseConfig.use_case_id) {
        setUseCaseId(props.useCaseConfig.use_case_id);
      }
      setConfig(props.useCaseConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.useCaseConfig]);

  // set different endpoint if provided
  useEffect(() => {
    if (props.endpoint) {
      setEndpoint(props.endpoint);
    }
  }, [props, setEndpoint]);

  // PUT BACK FOR COOKIES
  const hasCompletedChat = Cookies.get(completedChatCookie) === "true";
  useEffect(() => {
    if (hasCompletedChat) {
      // If the user has completed the chat, you can redirect them to a different URL
      window.location.href = "https://www.google.com";
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (sessionInProgress) {
      const heartbeatInterval = setInterval(
        () => sendHeartbeat(sessionId),
        HEARTBEAT * 60 * 1000
      ); // Send heartbeat every 5 seconds
      console.log("sessions started");
      console.log("session id:", sessionId);
      setSessionId(sessionId);
      return () => {
        clearInterval(heartbeatInterval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionInProgress, sessionId]);

  useEffect(() => {
    console.log("Appointment ID:", appointmentId);
    // Start session automatically with appointment ID
    if (appointmentId) {
      console.log(`Starting session for appointment ID: ${appointmentId}`);
      setSession(appointmentId); // Call this if it starts the session
    }
    if (salesBot) {
      console.log("Sales bot session started");
      setSessionIDRequired(false);
      setChatMessages([
        { role: "assistant", content: "Hi there! How can I help you today?" },
      ]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="app-container">
      <button
        className="gearbox"
        onClick={() => setShowConfig(!showConfig)}
        style={{ display: "none" }}
      >
        <FiSettings />
      </button>
      <ChatSessionManager
        onSessionEnd={onSessionEnd}
        sessionInProgress={sessionInProgress}
        sessionId={sessionId}
        userName={userName}
      />
      <ChatWindow
        chatMessages={chatMessages}
        handleSendMessage={handleSendMessage}
        loading={loading}
        userMessage={userMessage}
        setUserMessage={setUserMessage}
        testing={true}
        headerText={headerText}
        setSession={setSession}
        isInputEnabled={isInputEnabled}
        agent_name={agentName}
        agentTitle={agentTitle}
        user_name={userName}
        setUserName={setUserName}
        placeholder={placeholder}
        isBotStarting={isBotStarting}
        isAgentTyping={isAgentTyping}
        avatarURL={avatarURL}
        setAvatarUrl={setAvatarUrl}
        completedChatCookie={completedChatCookie}
        cookieEnabled={props.cookieEnabled}
        appointmentId={appointmentId}
        showCredit={showCredit}
        requestAudioAuthorization={requestAudioAuthorization}
        showTTSandSTTIcons={showTTSandSTTIcons}
      />
    </div>
  );
}

export default MeetingChatBot;
