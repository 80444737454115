import React from "react";
import { useParams } from "react-router-dom";
import MeetingChatBot from "./MeetingChatBot"; // Adjust the import path as necessary

function ChatBotWrapper() {
  const { appointmentId } = useParams();
  const useCaseConfig = {
    company_id: 1,
  };
  return (
    <MeetingChatBot
      systemMessage={{ appointmentId: appointmentId }}
      appointmentId={appointmentId}
      header="Chat with your manager"
      useCase="19"
      cookieEnabled={false}
      useCaseConfig={useCaseConfig}
      showTTSandSTTIcons={false}
    />
  );
}

export default ChatBotWrapper;
