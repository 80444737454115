import React, { useEffect } from "react";
// import Cookies from 'js-cookie'; // Import the js-cookie library
// import '../App.css';
import { FiSettings } from "react-icons/fi";
import ChatWindow from "./ChatWindow";
import Cookies from "js-cookie"; // Import the js-cookie library
import useChatBot from "./useChatBot";
import ChatSessionManager from "./ChatSessionManager";

function ChatBot(props) {
  // State and other variables...

  const headerText = props.header;
  const HEARTBEAT = process.env.REACT_APP_HEARTBEAT;

  const {
    chatMessages,
    userMessage,
    setUserMessage,
    showConfig,
    setShowConfig,
    isInputEnabled,
    agentName,
    agentTitle,
    userName,
    setUserName,
    placeholder,
    sessionId,
    isBotStarting,
    isAgentTyping,
    loading,
    handleSendMessage,
    setSession,
    avatarURL,
    setAvatarUrl,
    completedChatCookie,
    onSessionEnd,
    sessionInProgress,
    sendHeartbeat,
    setToken,
    setUserId,
    setPlatformId,
    setCompanyId,
    requestAudioAuthorization,
  } = useChatBot(
    1,
    props.systemMessage,
    props.useCase,
    props.surveyLink,
    false
  );

  useEffect(() => {
    if (
      props.useCaseConfig &&
      props.useCaseConfig.token &&
      props.useCaseConfig.company_id &&
      props.useCaseConfig.platform_id &&
      props.useCaseConfig.user_id
    ) {
      setToken(props.useCaseConfig.token);
      setUserId(props.useCaseConfig.user_id);
      setPlatformId(props.useCaseConfig.platform_id);
      setCompanyId(props.useCaseConfig.company_id);
    }
    // eslint-disable-next-line
  }, [props.useCaseConfig]); // Add useCaseConfig as a dependency

  // PUT BACK FOR COOKIES
  const hasCompletedChat = Cookies.get(completedChatCookie) === "true";
  useEffect(() => {
    if (hasCompletedChat) {
      // If the user has completed the chat, you can redirect them to a different URL
      window.location.href = "https://www.google.com";
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (sessionInProgress) {
      const heartbeatInterval = setInterval(
        () => sendHeartbeat(sessionId),
        HEARTBEAT * 60 * 1000
      ); // Send heartbeat every 5 seconds

      return () => {
        clearInterval(heartbeatInterval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionInProgress, sessionId]);
  return (
    <div className="app-container">
      <button
        className="gearbox"
        onClick={() => setShowConfig(!showConfig)}
        style={{ display: "none" }}
      >
        <FiSettings />
      </button>
      <ChatSessionManager
        onSessionEnd={onSessionEnd}
        sessionInProgress={sessionInProgress}
        sessionId={sessionId}
        userName={userName}
      />
      <ChatWindow
        chatMessages={chatMessages}
        handleSendMessage={handleSendMessage}
        loading={loading}
        userMessage={userMessage}
        setUserMessage={setUserMessage}
        testing={false}
        headerText={headerText}
        setSession={setSession}
        isInputEnabled={isInputEnabled}
        agent_name={agentName}
        agentTitle={agentTitle}
        user_name={userName}
        setUserName={setUserName}
        placeholder={placeholder}
        isBotStarting={isBotStarting}
        isAgentTyping={isAgentTyping}
        avatarURL={avatarURL}
        setAvatarUrl={setAvatarUrl}
        completedChatCookie={completedChatCookie}
        cookieEnabled={props.cookieEnabled}
        requestAudioAuthorization={requestAudioAuthorization}
      />
    </div>
  );
}

export default ChatBot;
